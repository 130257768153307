<template>
  <v-container>
    <v-card class="pa-4">
      <v-card-title>
        <span class="headline">Website Enquiries</span>
        <v-spacer></v-spacer>
        <v-text-field
          class="search-bar"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          outlined
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        v-if="enquiries"
        :headers="headers"
        :items="enquiries"
        :search="search"
        class="elevation-1"
        :items-per-page="10"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import RestResource from "../../../services/dataServiceWebsite";
const service = new RestResource();
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Phone", value: "phone", sortable: true },
      ],
      enquiries: [],
    };
  },
  mounted() {
    this.loadEnquiries();
  },
  methods: {
    loadEnquiries() {
      this.$setLoader();
      service.getEnquiry().then((r) => {
        this.enquiries = r.data.enquiries;
        this.$disableLoader();
      });
    },
  },
};
</script>

<style scoped>
.v-container {
  margin: 0 auto;
}

.v-card {
  background-color: #f9f9f9;
  padding: 20px;
}

.headline {
  font-weight: bold;
  color: #2c3e50;
}

.search-bar {
  max-width: 300px;
}

.v-data-table {
  margin-top: 20px;
}

.v-data-table .v-data-table__wrapper {
  max-height: 600px;
}

.v-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
